import axios from "axios"

const baseUrl = "https://proxy.sharkdev.com.br/?url=https://api.agendor.com.br/v3"

export const validPiperunToken = async (token) => {
    try {
        let result = await axios.get("https://api.pipe.run/v1/auth", {
            headers: {
                token
            }
        })

        return result.data?.success || false
    } catch (error) {
        throw error
    }
}

export const getPiperunPipelines = async (token) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get("https://api.pipe.run/v1/pipelines?show=200", {
            headers: {
                token
            }
        })

        if (!result?.data?.success)
            throw { message: "Ocorreu um erro ao buscar os finis" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const getPiperunStages = async (token, pipeline_id) => {
    try {
        if (!token)
            throw { message: "Token não informado" }

        let result = await axios.get(`https://api.pipe.run/v1/stages?show=200&pipeline_id=${pipeline_id}`, {
            headers: {
                token
            }
        })

        if (!result?.data?.success)
            throw { message: "Ocorreu um erro ao buscar os finis" }

        return result.data?.data
    } catch (error) {
        throw error
    }
}

export const getPiperunStatus = async () => [
    { id: 0, label: "Aberta", primaryColor: "#1C84C6", secondaryColor: "#ffffff" },
    { id: 1, label: "Ganho", primaryColor: "#1AB394", secondaryColor: "#ffffff" },
    { id: 3, label: "Perda", primaryColor: "#ED5565", secondaryColor: "#ffffff" },
]

export const getPiperunTriggers = async () => [
    { label: "Uma oportunidade entrar na etapa selecionada", value: "Uma oportunidade entrar na etapa selecionada" },
    { label: "Uma oportunidade sair da etapa selecionada", value: "Uma oportunidade sair da etapa selecionada" },
    { label: "Uma oportunidade ficar x dias na etapa selecionada", value: "Uma oportunidade ficar x dias na etapa selecionada" },
    { label: "Uma oportunidade for ganha", value: "Uma oportunidade for ganha" },
    { label: "Uma oportunidade for perdida", value: "Uma oportunidade for perdida" },
    { label: "Uma oportunidade for congelada", value: "Uma oportunidade for congelada" },
    { label: "Uma oportunidade for descongelada", value: "Uma oportunidade for descongelada" },
    { label: "Uma oportunidade for reaberta", value: "Uma oportunidade for reaberta" },
    { label: "Uma proposta for aprovada", value: "Uma proposta for aprovada" },
    { label: "Uma proposta for visualizada por um convidado", value: "Uma proposta for visualizada por um convidado" },
    { label: "A assinatura eletrônica de um documento for realizada", value: "A assinatura eletrônica de um documento for realizada" },
    { label: "A assinatura eletrônica de um documento for concluída", value: "A assinatura eletrônica de um documento for concluída" },
    { label: "Uma atividade for concluída", value: "Uma atividade for concluída" },
    { label: "O status da atividade for definido como no-show", value: "O status da atividade for definido como no-show" },
    { label: "Uma oportunidade for enviada para a lixeira", value: "Uma oportunidade for enviada para a lixeira" },
    { label: "Uma oportunidade for restaurada da lixeira", value: "Uma oportunidade for restaurada da lixeira" }
]

const Piperun = {
    pluginName: "Piperun",
    crmName: "Piperun",
    resourceKey: "piperunPlugin",
    activeMessagesResourceKey: "piperunPlugin_ActiveMessages",
    docUrl: "https://docs.sharkdev.com.br/blip/piperun",
    showWebhook: true,
    defaultStatus: () => [0, 1, 3],
    variablesTips: () => [
        { text: "Nome da pessoa", value: "{{person.name}}" },
        { text: "Nome do pipeline", value: "{{pipeline.name}}" },
        { text: "Nome da etapa", value: "{{stage.name}}" }
    ],
    middlewareForBlipService: "piperun",

    validToken: validPiperunToken,
    getPipelines: getPiperunPipelines,
    getStages: getPiperunStages,
    getTriggers: getPiperunTriggers,
    getStatus: getPiperunStatus
}

export default Piperun;